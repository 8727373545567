/* src/components/DashboardLayout.css */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 16rem; /* Adjust width as needed */
    background-color: var(--primary-color);
  }

.main {
    flex: 1;
    background-color: #f3f4f6; /* Adjust the background color as needed */
  }
  
  .content-area {
    /*padding: 1.5rem; /* Adjust padding as needed */
  }
  
  @media (min-width: 768px) {
    .content-area {
      padding-left: 20rem;
      padding-right: 4rem;
      padding-top: 4rem;
    }
  }
  
  h1 {
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
    font-weight: 700;
    color: #111827; /* Adjust the color as needed */
  }
  
  h2 {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    font-weight: 600;
    color: #111827; /* Adjust the color as needed */
    padding-bottom: 1.25rem;

  }
  
  h3 {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
    font-weight: 600;
    color: #111827; /* Adjust the color as needed */
  }
  
  p {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    color: #4b5563; /* Adjust the color as needed */
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  