/* src/components/wizard_components/Slider.css */
.slider-container {
    margin: 20px 0;
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  