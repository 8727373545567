.checkmark-item {
    display: flex;
    align-items: start;
    margin: 0rem 0;
  }
  
  .checkmark-item input[type="checkbox"] {
    margin-right: 0.75rem;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.25rem;
    border: 1px solid #d1d5db;
    background-color: white;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .checkmark-item input[type="checkbox"]:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .checkmark-item label {
    font-weight: 500;
    color: #1f2937;
  }
  
  .checkmark-item p {
    color: #6b7280;
  }
  