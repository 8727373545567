/* src/components/wizard_components/Radiothumbs.css */
.radiothumbs {
    display: flex;
    flex-wrap: wrap;
  }
  
  .radiothumb {
    margin: 7px;
    padding: 10px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-image 0.3s, color 0.3s;
    width: 230px; /* Set fixed width */
    height: 54px; /* Set fixed height */
    text-align: left; /* Center align text */
    padding-left: 45px;
    color: black; /* Set base text color to black */
  }
  
  .radiothumb:hover {
    background-size: cover;
    background-position: center;
  }
  
  .radiothumb.selected {
    background-size: cover;
    background-position: center;
    color: white; /* Set text color to white when selected */
    font-weight: bold; /* Make text bold when selected */
  }
  
  .radiothumb-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Make content take full width */
  }
  
  .radiothumb-content .text {
    flex-grow: 1; /* Make text take available space */
    font-size: 0.825em; /* Decrease text size */
  }
  