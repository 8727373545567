/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #264653;
  --secondary-color: #2A9D8F;
  --tertiary-color: #A8DBDD;
  --contrast-color-1: #DDC958;
  --contrast-color-2: #F56262;
  --font-family: 'Red Hat Display', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--tertiary-color);
  color: var(--primary-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  font-family: var(--font-family);
}

a {
  color: var(--secondary-color);
  font-family: var(--font-family);
}

a:hover {
  color: var(--tertiary-color);
}

.main-content {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

@keyframes fly-in {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-fly-in {
  animation: fly-in 0.5s ease-out forwards;
}

/* Sidebar specific styles */
.sidebar-link {
  color: white;
}

.sidebar-link:hover {
  color: white; /* Ensure text color remains white on hover */
  background-color: rgba(var(--secondary-color-rgb), 0.5); /* Ensure background color is 50% opaque */
}

.sidebar-link:hover .group-hover\:text-white {
  color: white !important; /* Ensure the icon color remains white on hover */
}

.bg-secondary-color {
  background-color: var(--secondary-color) !important; /* Ensure solid secondary color background for active link */
}

.hover\:bg-opacity-50:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.5); /* 50% opaque secondary color on hover */
}

/* Mobile navigation specific styles */
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.mobile-nav .logo {
  height: 40px;
}

.mobile-nav .menu-icon {
  cursor: pointer;
  color: white;
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding-top: 100px; /* To account for the navbar height */
}

.mobile-sidebar.open {
  transform: translateX(0);
}

.mobile-sidebar nav ul {
  padding-left: 20px; /* Adjust padding for mobile menu */
}

.mobile-sidebar nav li a {
  padding-left: 40px; /* Ensure padding aligns with the logo */
  padding: 12px;
  display: block;
  color: white;
}

.mobile-sidebar nav li a:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.5);
}

/* Adjustments for padding and alignment */
@media (min-width: 768px) {
  .main-content {
    padding-left: 0 !important; /* Remove left padding for main content on desktop */
  }
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 100px; /* Add top padding for main content on mobile */
  }
}

/* Custom button styles */
.btn-link {
  color: white;
  text-decoration: none;
}

.btn-link:hover {
  color: white;
}

.office-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.office-group h3 {
  margin-right: 1rem;
}

@media (min-width: 1200px) {
  .space-y-5 {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .space-y-5 > div {
    flex: 0 0 calc(50% - 1rem);
  }
}

.custom-slider::-webkit-slider-thumb {
  background: #2A9D8F !important;
}

.custom-range::-moz-range-thumb {
  background: #2A9D8F !important;
}

.custom-range {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(to right, 
    #e5e7eb 0%, #e5e7eb 50%, 
    #fee2e2 50%, #fee2e2 100%
  );
  outline: none;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2A9D8F !important;
  cursor: pointer;
  margin-top: -5px; /* Centers the thumb vertically */
}

.custom-range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2A9D8F !important;
  cursor: pointer;
  margin-top: -5px; /* Centers the thumb vertically */
}

.custom-range::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 4px;
}

.custom-range::-moz-range-track {
  height: 8px;
  border-radius: 4px;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-ping {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes rotate360 {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.group:hover .group-hover\:rotate-360 {
  animation: rotate360 1s linear infinite;
}

/* Editor Styles */

/* Rounded corners for the editor */
.rounded-editor .wrapper-class {
  border-radius: 0.5rem; /* 8px rounded corners */
  overflow: hidden;
}

.rounded-editor .editor-class {
  border-radius: 0.5rem;
  padding: 1rem;
}

/* Nice border for the editor container */
.rounded-editor .border-editor {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Rounded toolbar */
.rounded-editor .rdw-editor-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-bottom: 0;
  border-bottom: 1px solid #e5e7eb;
}

/* Reduce heading margins and paddings */
.rounded-editor .public-DraftEditor-content h1 {
  margin-bottom: 0.5rem !important;
  padding-bottom: 0.25rem !important;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.rounded-editor .public-DraftEditor-content h2 {
  margin-bottom: 0.5rem !important;
  padding-bottom: 0.25rem !important;
  font-size: 1.5rem;
  line-height: 2rem;
}

/* Optional: Improve toolbar button styles */
.rounded-editor .rdw-option-wrapper {
  height: 1.9rem;       /* Adjust the height as needed */
  min-height: 1.9rem;   /* Ensure the height doesn't shrink */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
  margin-right: 5px;
}

.rounded-editor .rdw-option-active {
  background-color: #f3f4f6;
  box-shadow: 1px 1px 0px #d1d5db;
}

/* Improve focus state */
.rounded-editor .DraftEditor-root {
  padding: 0.5rem;
}

/* Optional: Improve the dropdown menus in the toolbar */
.rounded-editor .rdw-dropdown-wrapper {
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
}

.rounded-editor .rdw-dropdown-optionwrapper {
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.rounded-editor .public-DraftEditor-content .custom-h1 {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.rounded-editor .public-DraftEditor-content .custom-h2 {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 2.5rem !important;
  font-size: 1.5rem;
  line-height: 2rem;
}

.rounded-editor .public-DraftEditor-content .custom-h3 {
  margin-bottom: 0 !important;
  margin-top: 2.5rem !important;
  padding-bottom: 0 !important;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.rounded-editor .border-editor {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 600px; /* Set a fixed height - adjust as needed */
  display: flex;
  flex-direction: column;
}

/* Make the toolbar stick to the top */
.rounded-editor .rdw-editor-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-bottom: 0;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

/* Make the editor content area scrollable */
.rounded-editor .wrapper-class {
  border-radius: 0.5rem;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rounded-editor .editor-class {
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-y: auto;
  flex: 1;
}

/* Fix for continuing numbered lists across bullet points */
.rounded-editor .public-DraftEditor-content h3 {
  counter-reset: orderedlist; /* Reset counter at each h3 heading */
}

.rounded-editor .public-DraftEditor-content ol {
  list-style-type: none;
  /* Don't reset counter here anymore */
}

.rounded-editor .public-DraftEditor-content ol li {
  counter-increment: orderedlist;
  position: relative;
  margin-left: 1.5em;
}

.rounded-editor .public-DraftEditor-content ol li:before {
  content: counter(orderedlist) ".";
  position: absolute;
  left: -1.5em;
}

/* Create a nested counter for sublists */
.rounded-editor .public-DraftEditor-content ol ol {
  counter-reset: sublist;
}

.rounded-editor .public-DraftEditor-content ol ol li {
  counter-increment: sublist;
  margin-left: 2em;
}

.rounded-editor .public-DraftEditor-content ol ol li:before {
  content: counter(orderedlist) "." counter(sublist) ".";
  left: -2em;
}

/* Don't affect unordered lists within ordered lists */
.rounded-editor .public-DraftEditor-content ol li ul {
  margin-left: 1em;
  list-style-type: disc;
}

.rounded-editor .public-DraftEditor-content ol li ul li {
  counter-increment: none;
  margin-left: 0;
}

.rounded-editor .public-DraftEditor-content ol li ul li:before {
  content: none;
}

/* Adjusted positioning for list numbers */
.rounded-editor .public-DraftEditor-content ol li {
  counter-increment: orderedlist;
  position: relative;
  margin-left: 2em; /* Increased from 1.5em to 2em */
}

.rounded-editor .public-DraftEditor-content ol li:before {
  content: counter(orderedlist) ".";
  position: absolute;
  left: -2em; /* Increased from -1.5em to -2em */
  width: 1.8em; /* Set a fixed width for the number container */
  text-align: right; /* Align numbers to the right within their container */
  padding-right: 0.3em; /* Add some space between number and content */
}

/* Also adjust nested lists */
.rounded-editor .public-DraftEditor-content ol ol li {
  counter-increment: sublist;
  margin-left: 2.5em; /* Increased for nested lists */
}

.rounded-editor .public-DraftEditor-content ol ol li:before {
  content: counter(orderedlist) "." counter(sublist) ".";
  left: -2.5em; /* Increased for nested lists */
  width: 2.3em; /* Wider for compound numbers */
}

.rdw-table-modal {
  height: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.rdw-table-wrapper {
  display: block;
}

table.rdw-table {
  border-collapse: collapse;
  width: 100%;
}

table.rdw-table td {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 60px;
}

.editor-class table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
}

.editor-class table td,
.editor-class table th {
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: top;
}

.editor-class table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.editor-class table tr:hover {
  background-color: #f2f2f2;
}
