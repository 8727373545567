/* src/components/wizard_components/RadioButtons.css */
.radio-buttons {
    display: flex;
    flex-direction: column;
  }
  
  .radio-button-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-button-input {
    margin-right: 1.75rem;
    height: 1.25rem;
    width: 1.25rem;
  }

  .radio-button-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .radio-button-text {
    font-size: 0.9rem;
    color: #333;
  }
  